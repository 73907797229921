<template>
    <div class="content-text" :class="contentClasses">
        <content-compiled :html="html" />
    </div>
</template>

<script>
export default {
    name: 'ContentText',
    props: {
        html: {
            required: false,
            type: String,
            default: null,
        },
        contentColor: {
            required: false,
            type: String,
            default: 'text-gray-600',
        },
        textCentered: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
    },
    computed: {
        contentClasses () {
            const cls = {};
            cls['max-w-2xl'] = true;
            cls[this.contentColor] = true;
            cls['text-center mx-auto'] = !!this.textCentered;
            return cls;
        },
    },
};
</script>
