<template>
    <NuxtLayout>
        <sections>
            <section-text
                :heading="errorTitle"
                :content="'<p>' + errorMessage + '</p>'"
            ></section-text>
        </sections>
    </NuxtLayout>
</template>

<script setup lang="ts">
import type {NuxtError} from '#app';

const props = defineProps({
    error: Object as () => NuxtError,
});

const errorTitle = computed(() => {
    if (!props.error) return '500 Server Error';
    switch (props.error.statusCode) {
        case 404:
            return '404 Not Found';
        default:
            return '500 Server Error';
    }
});

const errorMessage = computed(() => {
    if (!props.error || !props.error.statusMessage) return 'Something went wrong, please contact support.';
    return props.error.statusMessage + '.';
});

/* @ts-ignore */
useSeo().buildHead({
    title: errorTitle.value,
    description: errorMessage.value,
    index: false,
});
</script>
