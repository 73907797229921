<template>
    <div class="sections">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Sections',
};
</script>
