<template>
    <div class="layout-contained container mx-auto px-6 md:px-8 lg:px-10">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'LayoutContained',
};
</script>
