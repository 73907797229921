export default function () {
    const seo = {};
    const $config = useRuntimeConfig().public;
    const $route = useRoute();

    const url = $config.base + $route.path;

    // Set default values
    const properties = {
        canonical: url,
        index: 'noindex, follow', // Defaults to no index!
        title: $config.siteName,
        description: $config.siteStrapline,
        image: $config.images + $config.siteOpenGraphLogo,
        type: 'article',
    };

    // Add required scripts to this array
    const script = [];

    // Computed meta information
    const meta = function (videoUrl) {
        const result = [
            // HTML Meta
            {hid: 'robots', name: 'robots', content: properties.index},
            {hid: 'description', name: 'description', content: properties.description},
            {hid: 'author', name: 'author', content: $config.siteName},
            {hid: 'publisher', name: 'publisher', content: $config.siteName},

            // Open Graph
            {hid: 'og:locale', property: 'og:locale', content: 'en_GB'},
            {hid: 'og:type', property: 'og:type', content: properties.type},
            {hid: 'og:url', property: 'og:url', content: url},
            {hid: 'og:site_name', property: 'og:site_name', content: $config.siteName},
            {hid: 'og:title', property: 'og:title', content: properties.title},
            {hid: 'og:description', property: 'og:description', content: properties.description},
            {hid: 'og:image', property: 'og:image', content: properties.image},
        ];
        if (videoUrl) result.push({hid: 'og:video', property: 'og:video', content: videoUrl});
        return result;
    }

    // Computed links
    const link = function () {
        return [
            {rel: 'canonical', href: properties.canonical},
        ];
    };

    // Updates head using nuxt composable
    seo.buildHead = function ({type, title, description, index, image, video, canonical, structuredData}) {
        if (index === undefined) console.warn(`Indexing status is inferred in the '${$route.name}' page.`);
        if (canonical) properties.canonical = canonical;
        if (!!index) properties.index = 'index, follow';
        if (title) properties.title = title;
        if (description) properties.description = description;
        if (image) properties.image = image;
        if (type) properties.type = type;
        if (structuredData) script.push(structuredData);
        useHead({
            title: properties.title,
            link: link(),
            meta: meta(video),
            script,
        });
    };

    // Updates head to inform user page is loading
    seo.buildHeadLoading = function () {
        useHead({
            title: 'Loading...',
        });
    };

    // Updates head to inform user entity not found
    seo.buildHeadNotFound = function () {
        useHead({
            title: 'Not Found | ' + $config.siteName,
        });
    };

    return seo;
};
