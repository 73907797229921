
import axios from 'axios';

function formatUTCDateTime(date) {
    return date.toISOString().slice(0, 19).replace('T', ' ');
}

function parseUTCDateTime(str) {
    return new Date(str.replace(' ', 'T') + 'Z');
}

function convertDates (obj) {
    for (let p in obj) {
        if (!obj.hasOwnProperty(p)) continue;
        if (typeof obj[p] === 'object' && !(obj[p] instanceof Date)) convertDates(obj[p]);
        if (/^\d{4}-\d{2}-\d{2}( \d{2}:\d{2}:\d{2})?$/.test(obj[p])) obj[p] = parseUTCDateTime(obj[p]);
    }
    return obj;
}

function serialize (obj, prefix) {
    let str = [];
    for (let p in obj) {
        if (!obj.hasOwnProperty(p)) continue;
        let k = prefix ? prefix + '[' + p + ']' : p, v = obj[p];
        if (v instanceof Date) {
            str.push(encodeURIComponent(k) + '=' + encodeURIComponent(formatUTCDateTime(v)));
        } else if (v && typeof v === 'object') {
            str.push(serialize(v, k));
        } else if (v === null) {
            str.push(encodeURIComponent(k) + '=');
        } else if (v === undefined) {} else {
            str.push(encodeURIComponent(k) + '=' + encodeURIComponent(v));
        }
    }
    return str.join('&');
}

export default defineNuxtPlugin(nuxt => {
    const $config = useRuntimeConfig().public;

    // For third-party requests
    const api = axios.create({});

    api.interceptors.request.use(async axiosConfig => {
        const NUXT_TOKEN = process.env.SSM_NUXT_TOKEN;
        if (NUXT_TOKEN) {
            const nuxtCookieString = `nuxt_token=${NUXT_TOKEN}`;
            const cookieHeader = axiosConfig.headers.cookie;
            if (cookieHeader) {
                axiosConfig.headers.cookie = `${cookieHeader}; ${nuxtCookieString}`;
            } else {
                axiosConfig.headers.cookie = nuxtCookieString;
            }
        }

        // Ensure post requests have correct content-type
        if (axiosConfig.method && axiosConfig.method === 'post') {
            axiosConfig.headers['content-type'] = 'application/x-www-form-urlencoded';
        }

        if ($config.app) axiosConfig.headers['X-Client-App'] = $config.app;
        if ($config.app) axiosConfig.headers['X-Client-App-Version'] = $config.appVersion;

        if (process.client) axiosConfig.headers['Viewport-Width'] = window.innerWidth || undefined;
        if (process.client) axiosConfig.headers['Viewport-Height'] = window.innerHeight || undefined;

        return axiosConfig;
    });

    api.interceptors.request.use(function (axiosConfig) {
        axiosConfig.transformRequest = [function (data) {
            return serialize(data);
        }];
        return axiosConfig;
    });

    api.interceptors.response.use(function (response) {
        response.data = convertDates(response.data.data);
        return response;
    }, async function (error) {
        if (error.response) {
            if (error.response.data.messages.error_messages.length) {
                if (error.response.data.messages.system_messages.length) console.error('API: ' + error.response.data.messages.system_messages[0].message);
                error.alert = error.response.data.messages.error_messages[0].message;
            } else if (error.response.status === 404) {
                error.alert = 'The resource you requested cannot be found, please try again later or contact support.';
            } else {
                error.alert = 'Something went wrong, please try again or contact support.';
            }
        } else if (error.request) {
            error.alert = 'The connection to the server timed out. Please try refreshing the page, clearing your browser or contacting support.';
        } else {
            error.alert = 'An unknown error occurred. Please try contacting support.';
        }
        return Promise.reject(error);
    });

    nuxt.provide('api', api);
})
