<template>
    <div class="article-home">
        <p>
            Contact us with your requirements.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleHome',
};
</script>
